import React from "react";
import styles from "./ErrorBlade.module.css";

export const WarningsBlade = ({
  environment,
  warnings,
}: {
  environment: string;
  warnings: string[];
}) => {
  const allowedEnvironments = ["test", "preview"];

  if (allowedEnvironments.includes(environment) && warnings.length > 0) {
    console.warn(warnings.join("\n"));
    return (
      <div
        data-testid="error-blade"
        className={styles.warningWrapper}
        id="dev-all-warnings"
      >
        <a href="#dev-all-warnings" className={styles.anchor}>
          !
        </a>
        <h2 className={styles.heading}>Warnings</h2>
        {warnings.map((warning, i) => (
          <pre key={i} className={styles.errorMessage}>
            - {warning}
          </pre>
        ))}
      </div>
    );
  }
  return null;
};
