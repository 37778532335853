import { metrics } from "@riotgames/pubhub-sdk";
import React from "react";
import { ErrorBlade } from "./ErrorBlade";

// TODO: This should use the INTERNAL_ENV environment variable instead of a list of strings;
const INTERNAL_ENVIRONMENTS = ["test", "preview"];

type BladeErrorProps = {
  environment: string;
  hasError?: boolean;
  error?: unknown;
  children?: React.ReactElement;
};
export class BladeErrorBoundary extends React.Component<BladeErrorProps> {
  state = { hasError: false, error: null, environment: "live" };
  constructor(props: BladeErrorProps) {
    super(props);
    this.state.environment = props.environment || "live";
  }
  static getDerivedStateFromError(error: unknown) {
    return { hasError: true, error };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    console.error(error, errorInfo);
    if (typeof window !== "undefined" && error instanceof Error) {
      metrics.noticeError(error);
    }
  }

  render() {
    if (this.state.hasError) {
      if (INTERNAL_ENVIRONMENTS.includes(this.state.environment)) {
        return (
          <ErrorBlade
            error={this.state.error}
            environment={this.state.environment}
          />
        );
      } else {
        return null;
      }
    }

    return this.props.children;
  }
}
