import React from "react";
import { AnalyticsProvider } from "@riotgames/blades-ui";
import { LooseBlade } from "../utils/api/types/blades";
import { RenderBlade } from "./RenderBlade";
import { BladeErrorBoundary } from "./BladeErrorBoundary";

interface BladesRendererProps {
  // In order to let a BladeErrorBoundary catch blade errors independently,
  // we have to assume that blades could possibly be undefined without breaking the whole page
  blades?: LooseBlade[];
  environment: string;
  locale: string;
}

export default function BladesRenderer({
  blades,
  environment,
  locale,
}: BladesRendererProps) {
  return (
    <>
      <header>
        {/* Temporary needded for sites that place riotbar in specific places */}
        <div id="riotbar-header" />
      </header>
      {blades?.map((bladeData, index) => {
        const bladePosition = `${index + 1}:${blades.length}`;

        return (
          <BladeErrorBoundary
            key={`${bladeData.type}-${bladePosition}`}
            environment={environment}
          >
            <AnalyticsProvider
              key={`${bladeData.type}-${bladePosition}`}
              blade={{
                id: bladeData.type,
                type: bladeData.type,
                position: bladePosition,
              }}
              uiLocation={`blade[${index + 1}:${blades.length}]/${
                bladeData.type
              }`}
            >
              <RenderBlade
                locale={locale}
                environment={environment}
                index={index}
                {...bladeData}
              />
            </AnalyticsProvider>
          </BladeErrorBoundary>
        );
      })}
      <footer>
        {/* Temporary needded for sites that place riotbar in specific places */}
        <div id="riotbar-footer" />
      </footer>
    </>
  );
}
