import Debug from "debug";
import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { metrics } from "@riotgames/pubhub-sdk";
import { HostTimeoutError } from "@riotgames/pubhub-sdk";
import { HubSdkFactoryOptions } from "@riotgames/pubhub-sdk";
import {
  HubSdk,
  HubSdkFactory,
  makeLcHubSdk,
  makeTftmHubSdk,
  makeRiotClientHubSdk,
  makeRiotMobileHubSdk,
  makeLorHubSdk,
} from "@riotgames/pubhub-sdk";
const debug = Debug("pubhub:sdk:use-hub-sdk");

type SdkKey = "LeagueClient" | "lor" | "tftm" | "RiotClient" | "RiotMobile";

const SUPPORTED_SDKS: Record<SdkKey, HubSdkFactory> = {
  LeagueClient: makeLcHubSdk,
  lor: makeLorHubSdk,
  tftm: makeTftmHubSdk,
  RiotClient: makeRiotClientHubSdk,
  RiotMobile: makeRiotMobileHubSdk,
};

const SDK_TIMEOUT = 25000;

export function useHubSdk(
  sdkFactoryName: string | undefined,
  options: HubSdkFactoryOptions
): HubSdk | null {
  const [sdk, setSdk] = useState<HubSdk | null>(null);

  const memoizedOptions = useMemo(() => options, [options]);

  const getSdkFactory = useCallback((): HubSdkFactory | null => {
    const sdkHost = sdkFactoryName as SdkKey | undefined;

    if (!sdkHost || !(sdkHost in SUPPORTED_SDKS)) {
      console.warn(`Invalid or missing PUBHUB_SDK_HOST: ${sdkHost}`);
      return null;
    }

    return SUPPORTED_SDKS[sdkHost];
  }, [sdkFactoryName]);

  const sdkFactoryRef = useRef<HubSdkFactory | null>(null);

  useEffect(() => {
    const sdkFactory = getSdkFactory();

    if (!sdkFactory) {
      sdkFactoryRef.current = null;
      return;
    }

    if (sdkFactoryRef.current === sdkFactory) {
      return;
    }

    sdkFactoryRef.current = sdkFactory;

    const handleErrors = (e: Error) => {
      metrics.noticeError(e as Error, { sdkFactory: sdkFactory.name });
    };

    const sdkTimeout = setTimeout(() => {
      debug(`SDK timeout executed: ${sdkTimeout}`);
      handleErrors(new HostTimeoutError("Timeout initializing HubSDK"));
    }, SDK_TIMEOUT);
    debug(`SDK timeout set: ${sdkTimeout}`);

    const sdkPromise = sdkFactory(memoizedOptions);
    sdkPromise
      .then((sdk) => {
        setSdk(sdk);
        metrics.registerSdkReady(sdk.options);
      })
      .catch(handleErrors)
      .finally(() => {
        debug(`SDK timeout cleared: ${sdkTimeout}`);
        clearTimeout(sdkTimeout);
      });

    return () => {
      debug(`Cleanup: SDK timeout cleared: ${sdkTimeout}`);
      clearTimeout(sdkTimeout);
      sdkPromise.then((sdk) => sdk.destroy());
    };
  }, [getSdkFactory, memoizedOptions]);

  return sdk;
}
