export function sanitizeString(str: string) {
  return str.replace(/[^a-zA-Z0-9-./]/g, "");
}

export function sanitizeChannelId(channelId: string) {
  return channelId.replace(/[^a-zA-Z0-9-._]/g, "");
}

/**
 * Sanitize a path string to only allow alphanumeric characters, dashes, periods, and slashes.
 * Note: we are allowing . and _ in the path to support existing legacy URLs.
 */
export function sanitizePath(str: string) {
  return str.replace(/[^a-zA-Z0-9-_/]/g, "");
}
