import React from "react";
import { ReactNode } from "react";
import { HubSdkProvider, HubSdk } from "@riotgames/pubhub-sdk";

interface WithSdkProviderProps {
  sdk: HubSdk | null;
  children: ReactNode;
}

export const WithSdkProvider = ({ sdk, children }: WithSdkProviderProps) => {
  if (sdk) {
    return <HubSdkProvider sdk={sdk}>{children}</HubSdkProvider>;
  }
  return <>{children}</>;
};
