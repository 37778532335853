import React from "react";
import styled from "styled-components";

// TODO: Commenting this out until it's added to blades-ui for v2
// import { AvailabilityMiniBlade } from "@riotgames/blades-ui";
const AvailabilityMiniBlade = styled.div``;

type Props = {
  locale?: string;
};
export const RenderAvailabilityMiniBlade = ({ locale = "en-us" }: Props) => {
  const loc = {
    "en-us": { title: "AVAILABLE ON" },
    "de-de": { title: "VERFÜGBAR ALS" },
    "es-es": { title: "DISPONIBLE EN" },
    "fr-fr": { title: "DISPONIBLE SUR" },
    "ja-jp": { title: "リリース中" },
    "pt-br": { title: "DISPONÍVEL EM" },
    "ko-kr": { title: "이용 가능" },
  } as const;
  const title =
    locale in loc ? loc[locale as keyof typeof loc].title : loc["en-us"].title;
  return <AvailabilityMiniBlade title={title} />;
};
