import React from "react";
import {
  ArticleCardCarousel,
  ArticleCardGrid,
  ArticleMasthead,
  ArticleMediaCarousel,
  ArticleMediaEmbed,
  ArticleMediaGallery,
  ArticlePromoCardGrid,
  ArticleRichTextAccordion,
  ArticleRichTextBlade,
  AssetHighlight,
  AudioPlayer,
  BetaCenteredMasthead,
  BetaConnectConsoleMasthead,
  BetaInviteMediaPromo,
  BetaKeyDisplayMasthead,
  BetaRegistrationMasthead,
  BetaSuccessMasthead,
  CenteredPromotion,
  CenterPromoMasthead,
  CharacterCardGrid,
  CharacterChanges,
  CharacterMasthead,
  ChooseFighter,
  EmailSubscribe,
  FeaturedNewsCarousel,
  FullWidthImage,
  GameBetaMasthead,
  GameDetailMasthead,
  GameSimpleMasthead,
  GameSpecs,
  IconTab,
  ImageCarousel,
  LandingMediaCarousel,
  LandingMediaEmbed,
  LandingPromoCardCarousel,
  LandingRichTextAccordion,
  LandingRichTextBlade,
  MediaMasthead,
  MediaPromoBlade,
  PatchNotesRichTextBlade,
  PlatformSelectionMasthead,
  RiotBar,
  SeparatorBlade,
  TextMasthead,
  TickerBanner,
} from "@riotgames/blades-ui";
import { metrics } from "@riotgames/pubhub-sdk";
import { LooseBlade } from "../utils/api/types/blades";
import { parseBladeData } from "../utils/api/validate";
import { ErrorBlade } from "./ErrorBlade";

export function RenderBlade({
  index,
  ...bladeProps
}: LooseBlade & { index: number; locale: string; environment: string }) {
  try {
    const { type, ...bladeData } = parseBladeData(bladeProps, index);
    switch (type) {
      case "articleCardCarousel":
        return <ArticleCardCarousel {...bladeData} />;
      case "articleCardGrid":
        return <ArticleCardGrid {...bladeData} />;
      case "articleMasthead":
        return <ArticleMasthead {...bladeData} />;
      case "articleMediaCarousel":
        return <ArticleMediaCarousel {...bladeData} />;
      case "articleMediaEmbed":
        return <ArticleMediaEmbed {...bladeData} />;
      case "articleMediaGallery":
        return <ArticleMediaGallery {...bladeData} />;
      case "articlePromoCardGrid":
        return <ArticlePromoCardGrid {...bladeData} />;
      case "articleRichTextAccordion":
        return <ArticleRichTextAccordion {...bladeData} />;
      case "assetHighlight":
        return <AssetHighlight {...bladeData} />;
      case "audioPlayer":
        return <AudioPlayer {...bladeData} />;
      case "centeredPromotion":
        return <CenteredPromotion {...bladeData} />;
      case "centerPromoMasthead":
        return <CenterPromoMasthead {...bladeData} />;
      case "characterCardGrid":
        return <CharacterCardGrid {...bladeData} />;
      case "characterChanges":
        return <CharacterChanges {...bladeData} />;
      case "characterMasthead":
        return <CharacterMasthead {...bladeData} />;
      case "chooseFighter":
        return <ChooseFighter {...bladeData} />;
      case "emailSubscribe":
        return <EmailSubscribe {...bladeData} />;
      case "featuredNewsCarousel":
        return <FeaturedNewsCarousel {...bladeData} />;
      case "fullWidthImage":
        return <FullWidthImage {...bladeData} />;
      case "gameBetaMasthead":
        return <GameBetaMasthead {...bladeData} />;
      case "gameDetailMasthead":
        return <GameDetailMasthead {...bladeData} />;
      case "gameSimpleMasthead":
        return <GameSimpleMasthead {...bladeData} />;
      case "gameSpecs":
        return <GameSpecs {...bladeData} />;
      case "iconTab":
        return <IconTab {...bladeData} />;
      case "imageCarousel":
        return <ImageCarousel {...bladeData} />;
      case "landingMediaCarousel":
        return <LandingMediaCarousel {...bladeData} />;
      case "landingMediaEmbed":
        return <LandingMediaEmbed {...bladeData} />;
      case "landingPromoCardCarousel":
        return <LandingPromoCardCarousel {...bladeData} />;
      case "landingRichTextAccordion":
        return <LandingRichTextAccordion {...bladeData} />;
      case "mediaPromo":
        return <MediaPromoBlade {...bladeData} />;
      case "articleRichText":
        return <ArticleRichTextBlade {...bladeData} />;
      case "patchNotesRichText":
        return <PatchNotesRichTextBlade {...bladeData} />;
      case "landingRichText":
        return <LandingRichTextBlade {...bladeData} />;
      case "riotbar":
        return "productId" in bladeData &&
          String(bladeData.productId) !== "false" ? (
          <RiotBar {...bladeData} />
        ) : null;
      case "separator":
        return <SeparatorBlade {...bladeData} />;
      case "textMarquee":
        return <TickerBanner {...bladeData} />;
      case "textMasthead":
        return <TextMasthead {...bladeData} />;
      case "betaCenteredMasthead":
        return <BetaCenteredMasthead {...bladeData} />;
      case "betaConnectConsoleMasthead":
        return <BetaConnectConsoleMasthead {...bladeData} />;
      case "betaInviteMediaPromo":
        return <BetaInviteMediaPromo {...bladeData} />;
      case "betaKeyDisplayMasthead":
        return <BetaKeyDisplayMasthead {...bladeData} />;
      case "betaSuccessMasthead":
        return <BetaSuccessMasthead {...bladeData} />;
      case "betaRegistrationMasthead":
        return <BetaRegistrationMasthead {...bladeData} />;
      case "mediaMasthead":
        return <MediaMasthead {...bladeData} />;
      case "platformSelectionMasthead":
        return <PlatformSelectionMasthead {...bladeData} />;
      default: {
        const _: never = type;
        throw new Error(`Unexpected blade type: ${_}`);
      }
    }
  } catch (error) {
    console.error(error);
    // Handle server-side errors with ErrorBlade:
    if (typeof window !== "undefined" && error instanceof Error) {
      metrics.noticeError(error);
    }
    return <ErrorBlade environment={bladeProps.environment} error={error} />;
  }
}
