import { PageSchema, Page, SitemapSchema, Sitemap } from "./types/pages";
import { LooseBlade } from "./types/blades";
import {
  AllBladeContracts,
  AllBladeContractsSchema,
} from "@riotgames/blades-ui";
import { ZodError } from "zod";

export function parsePageResponse(response: unknown): Page {
  try {
    return PageSchema.parse(response);
  } catch (error) {
    console.error(response);
    throw new Error(
      `Invalid response received from PCS API for the page details: ${error}`
    );
  }
}

export function parseSitemapResponse(response: unknown): Sitemap {
  try {
    return SitemapSchema.parse(response);
  } catch (error) {
    console.error(response);
    throw new Error(
      `Invalid response received from PCS API for the sitemap details: ${error}`
    );
  }
}

export class BladeValidationError extends Error {
  constructor(message: string, public cause: ZodError, public input: unknown) {
    super(message);
  }
}

export function parseBladeData(
  blade: LooseBlade,
  index: number
): AllBladeContracts {
  try {
    return AllBladeContractsSchema.parse(blade);
  } catch (cause) {
    if (cause instanceof ZodError) {
      throw new BladeValidationError(
        `Invalid Blade data from blade [${index + 1}:${blade.type}]`,
        cause,
        blade
      );
    }
    throw new Error(
      `Invalid Blade shape from blade [${index + 1}:${
        blade.type
      }]: ${cause} ${JSON.stringify(blade)}`,
      { cause: cause }
    );
  }
}
