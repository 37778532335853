import React from "react";
import { fromZodError } from "zod-validation-error";
import { BladeValidationError } from "../utils/api/validate";
import styles from "./ErrorBlade.module.css";

export const ErrorBlade = ({
  environment,
  error,
}: {
  environment: string;
  error: unknown;
}) => {
  const allowedEnvironments = ["test", "preview"];

  if (allowedEnvironments.includes(environment)) {
    return (
      <div data-testid="error-blade" className={styles.wrapper}>
        <h2 className={styles.heading}>Problem rendering blade</h2>
        {error instanceof BladeValidationError ? (
          <pre className={styles.errorMessage}>
            {fromZodError(error.cause, {
              issueSeparator: "\n * ",
              prefix: error.message,
              prefixSeparator: ":\n * ",
              unionSeparator: "\n    or ",
            }).toString()}
            {"\n\n"}
            Input Blade data:
            {"\n"}
            {JSON.stringify(error.input, null, 2)}
          </pre>
        ) : (
          <pre className={styles.errorMessage}>{String(error)}</pre>
        )}
      </div>
    );
  }
  return null;
};
