import { z } from "zod";

import { AllBladeContracts } from "@riotgames/blades-ui";

export const LooseBladeSchema = z.intersection(
  z.object({ type: z.string() }),
  z.record(z.string(), z.unknown())
);

export type LooseBlade = z.infer<typeof LooseBladeSchema>;
export type StrictBlade = AllBladeContracts;
